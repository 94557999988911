.ContactCard{
  background-color: transparent;
  width: 500px;
  height: 300px;
  perspective: 1000px;
}

.ContactCard h3, .ContactCard h4 {
  font-family: 'Train One', cursive;
}

.inner {
    position: relative;
    background-color: black;
    color: white;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 5px;
    padding-top: 10px;
}

.contact {
  display: flex;
  margin-bottom: 10px;
}

.contact img {
  margin-right: 13px;
}

.back {
  margin-left: 30%;
}

.back a {
  text-decoration: none;
  margin-top: 8px;
}
.back a:link    { color: white;  }
.back a:visited { color: white;   }
.back a:hover   { color: #9882AC; }
.back a:active  { color: #0C0910;  }

@media all and (max-width: 450px) {
  .back {
    margin-left: 5%;
  }
}