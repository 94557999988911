header {
    display: flex;
    justify-content: space-evenly;
    background-color: #8EF4D9;
    border-bottom: 1px solid black;
    width: 100%;
}

.header-contact {
    margin-top: 15px;
}

header img {
    margin-right: 20px;
}