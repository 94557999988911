body {
  background-color: #D4F2EA;
}

h1, h2, h3, h4 {
  font-family: 'Yatra One', cursive;
}

p, li, a {
  font-family: 'Varela Round', sans-serif;
}

header, footer, h2, h3, h4, .icons{
  text-align: center;
}

.App {
  position: relative;
  min-height: 100vh;
  width: 100%;
}

main {
  margin: 5% 10% 5% 10%;
}

.container {
    display: flex;
    justify-content: center;
}

@media (prefers-reduced-motion: no-preference) {
}