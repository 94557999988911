.tile .right {
    display: flex;
    padding: 10px;
    justify-content: center;
}

.stack, .screen {
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.proj, .tile .right {
    flex-direction: column;
    justify-content: center;
}

.tile-buttons {
    display: flex;
    justify-content: space-evenly;
}

.tile-buttons a {
    text-decoration: none;
    border: 1px solid black;
    border-radius: 5px;
    padding: 3px;
    background-color: #A8BFB9;
    width: 150px;
    text-align: center;
    margin-right: 10px;

    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.tile-buttons a:link    { color: black;  }
.tile-buttons a:visited { color: black;  }
.tile-buttons a:active  { color: black;  }

.tile-buttons a:hover   {
    background-color: white;
    color: black;
    width: 200px;

    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.tile .projIcon{
    margin-top: 18px;
    margin-right: 10px;
}

@media all and (min-width: 800px) and (max-width: 1100px) {
    .tile-buttons {
        flex-direction: column;
        justify-content: center;
    }
    .tile-buttons a {
        margin-bottom: 15px;
    }
}

@media all and (min-width: 800px) {
    .proj, .tile .right {
        flex-direction: row;
    }
    .stack {
        flex-direction: column;
    }
}