.Intro {
    text-align: center;
}

.Intro img {
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.Intro .right {
    text-align: left;
    margin-left: 20px;
}